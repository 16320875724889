<template>
    <div class="container" style="height: 100%">
        <div>
            <div class="cash-out">
                <div class="tit">提现金额</div>
                <div class="dz-flex dz-flex-between dz-aic border-b">
                    <div style="line-height: 1;">
                        <span class="symbol">¥</span>
                        <span class="amount">{{ urlParams.userAmount }}</span>
                    </div>
                    <div v-if="urlParams.errorMsg" class="err-msg">
                        暂时无法提现，请联系客服
                    </div>
                    <div v-else-if="parseInt(urlParams.userAmount) < parseInt(urlParams.limitAmount)" class="err-msg">
                        <span class="warn">!</span>最低提现金额{{ urlParams.limitAmount }}元
                    </div>
                </div>
                <div>
                    可提现金额：¥{{ urlParams.userAmount }}
                </div>
            </div>
            <div class="extract dz-flex dz-aic dz-flex-between">
                <div>提现方式</div>
                <div>
                    微信钱包
                    <img src="../../assets/images/drawing/wx.png">
                </div>
            </div>
            <div class="btn" @click="update">确定提现</div>
            <div class="service dz-flex-center" @click="$refs.service.open(true)">
                <img src="../../assets/images/drawing/service-icon.png" />
                <span>联系客服</span>
            </div>
        </div>
        <service ref="service" />
    </div>
</template>

<script>
import service from '@/components/service';
import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
export default {
    name: 'Index',
    components: { service },
    data() {
        return {
            urlParams: {}
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.urlParams = this.$route.query;
            this.urlParams.errorMsg && this.$refs.service.open(false);
            this.$ajax.post('/wx/jsapi/getJsapiTicket', {}, { noLoad: true }).then(data => {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: data.appId, // 必填，公众号的唯一标识
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                    signature: data.signature, // 必填，签名
                    jsApiList: ['hideAllNonBaseMenuItem', 'hideMenuItems'] // 必填，需要使用的 JS 接口列表
                });

                wx.ready(function() {
                    wx.hideAllNonBaseMenuItem();
                    wx.hideMenuItems({
                        menuList: [
                            'menuItem:share:appMessage',
                            'menuItem:share:timeline',
                            'menuItem:share:timeline',
                            'menuItem:share:qq',
                            'menuItem:share:weiboApp',
                            'menuItem:favorite',
                            'menuItem:share:facebook',
                            'menuItem:share:QZone',
                            'menuItem:editTag',
                            'menuItem:delete',
                            'menuItem:copyUrl'
                        ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
                    });
                });

                document.addEventListener('touchmove', function(e) {
                    e.preventDefault();
                }, { passive: false });
            });
        });
    },
    methods: {
        update() {
            const { ptu: ptUnionId, puo: ptOpenId, userAmount, limitAmount } = this.urlParams;
            if (parseInt(userAmount) < parseInt(limitAmount)) {
                Toast('最低提现金额' + limitAmount + '元');
                return;
            }
            this.$ajax.post('/wx/pay/entPay', { ptUnionId, ptOpenId }).then(res => {
                Toast('提现成功');
                this.urlParams.userAmount = 0;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.container {
  background: url("../../assets/images/drawing/bg.png") no-repeat;
  background-size: 1500px 260px;
  padding: 28px 24px 0;
  position: relative;
}

.cash-out {

  width: 702px;
  height: 276px;
  padding: 56px 28px 24px;
  background: rgba(255, 255, 255);
  opacity: 1;
  border-radius: 20px;

  .tit {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 24px;
  }

  .symbol {
    font-size: 44px;
    color: #33250F;
  }

  .amount {
    font-size: 60px;
    color: #33250F;
    margin-left: 12px;
  }

  .err-msg {
    font-size: 24px;
    color: #FF3131;
  }

  .border-b {
    border-bottom: 1px solid #EDEDED;
    margin-bottom: 24px;
  }
}

.extract {
  margin-top: 24px;
  font-size: 30px;
  color: #000000;
  height: 100px;
  background: rgba(255, 255, 255);
  border-radius: 20px;
  width: 702px;
  padding: 0 24px;

  img {
    vertical-align: middle;
    width: 34px;
    height: 30px;
  }
}

.btn {
  font-size: 32px;
  font-weight: bold;
  line-height: 80px;
  color: #FFFFFF;
  width: 686px;
  height: 80px;
  text-align: center;
  background: #FF2626;
  border-radius: 40px;
  margin-top: 112px;
}

.service {
  width: 208px;
  height: 56px;
  border: 1px solid #999999;
  border-radius: 56px;
  font-size: 24px;
  color: #999999;
  text-align: center;
  position: fixed;
  left: 272px;
  bottom: 98px;

  img {
    width: 28px;
    height: 28px;
  }
}
.warn{
  border: 1px solid #FF2626;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
</style>
