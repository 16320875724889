<template>
    <van-popup v-model="show" :close-on-click-overlay="overlay">
        <div class="bg">
            <div class="tip">
                <span>长按识别</span>
                添加专属客服
            </div>
            <div class="tip-err">提现失败，请联系客服解决！</div>
            <img class="code" src="https://oss.fjdaze.com/renrenbijia/elUpload/tixian.png?t=1652926492942" alt="">
            <div class="course">长按识别二维码添加</div>
        </div>
    </van-popup>
</template>

<script>
import { Popup } from 'vant';

export default {
    name: 'Service',
    components: {
        vanPopup: Popup
    },
    data() {
        return {
            show: false,
            overlay: false
        };
    },
    methods: {
        open(overlay) {
            this.show = true;
            this.overlay = overlay;
        }
    }
};
</script>

<style lang="scss" scoped>
.van-popup {
  background-color: transparent;
}

.bg {
  width: 540px;
  height: 700px;
  background: url("../assets/images/service/service.png");
  background-size: 100% 100%;
  padding-top: 198px;
  text-align: center;
}

.tip {
  font-size: 36px;
  font-weight: bold;
  color: #000000;

  span {
    color: #FF3131;
  }
}

.tip-err {
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
}

.course {
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.4);
}

.code {
  width: 280px;
  height: 280px;
  border: 1px solid #EDEDED;
  border-radius: 20px;
  margin: 24px 0;
}
</style>
